<template>
  <!-- 这个即是销售合同详情页面  又是录入结算价格页面 -->
  <div class="salesContractDetailsPageBox page-info-content">
    <div class="topBox">
      <div class="topTitle">
        <span>销售合同号：{{ detailObj.contractNo }}</span>
        <span>创建时间：{{ detailObj.createTime }}</span>
        <span>
          <el-button
            size="small"
            type="primary"
            plain
            @click="viewContract"
          >查看合同</el-button>
          <el-button
            size="small"
            type="primary"
            plain
            @click="checkDeliveryRecord"
          >垫资记录</el-button>
          <router-link style="margin-left:12px;" :to="{path:'/pickUpGoods/index', query:{contractNo: detailObj.contractNo}}"><el-button size="small" type="primary" plain>提货记录</el-button></router-link>
        </span>
      </div>
      <Details-page
        details-title=""
        :detail-obj="detailObj"
        :details-item-arr="detailsItemArr1"
      >
        <template #insertRows>
          <el-descriptions-item label="合同到期日">
            {{ detailObj.expiryContractDate }}
            <div v-if="detailObj.isOverdue==='1'" class="overdue-box">
              已逾期
            </div>
          </el-descriptions-item>
        </template>
      </Details-page>
    </div>
    <!-- 流程图 -->
    <div class="amountDetailBox">
      <div class="item">
        <img src="@/assets/image/banner5.png" alt="banner">
        <div class="textDescription">
          <div class="iconBox">
            <img src="@/assets/icon/icon1.png" alt="icon">创建合同
          </div>
          <p class="barrel">
            <span>合同数量：</span>{{ detailObj.contractWeight||0 }}吨
          </p>
          <p class="barrel">
            <span>合同总额：</span>￥{{ detailObj.aggregateAmount||0 }}
          </p>
          <p class="barrel">
            <span>保证金：</span>￥{{ detailObj.depositPayable||0 }}
          </p>
        </div>
      </div>
      <div class="item">
        <img src="@/assets/image/banner1.png" alt="banner">
        <div class="textDescription">
          <div class="iconBox">
            <img src="@/assets/icon/icon2.png" alt="icon">已付款
          </div>
          <p class="barrel">
            <span>已付金额(不含保证金)：</span>￥{{ detailObj.paidAmount||0 }}
          </p>
          <p class="barrel">
            <span>已付保证金：</span>￥{{ detailObj.depositPaid||0 }}
          </p>
        </div>
      </div>
      <div class="item">
        <img src="@/assets/image/banner2.png" alt="banner">
        <div class="textDescription">
          <div class="iconBox">
            <img src="@/assets/icon/icon3.png" alt="icon">已提货
          </div>
          <p class="barrel">
            <span>入库数量：</span>{{ detailObj.invWeight||0 }}吨
          </p>
          <p class="barrel">
            <span>提货数量：</span>{{ detailObj.deliveryWeight||0 }}吨
          </p>
          <p class="barrel">
            <span>剩余数量：</span>{{ (Number(detailObj.invWeight) - Number(detailObj.deliveryWeight))||0 }}吨
          </p>
        </div>
      </div>
      <div class="item">
        <img src="@/assets/image/banner3.png" alt="banner">
        <div class="textDescription">
          <div class="iconBox">
            <img src="@/assets/icon/icon4.png" alt="icon">已结算
          </div>
          <p class="barrel">
            <span>结算数量：</span>{{ detailObj.settleWeight||0 }}吨
          </p>
          <p class="barrel">
            <span>结算金额：</span>￥{{ detailObj.settleAmount||0 }}
          </p>
        </div>
      </div>
      <div class="item">
        <img src="@/assets/image/banner4.png" alt="banner">
        <div class="textDescription">
          <div class="iconBox">
            <img src="@/assets/icon/icon5.png" alt="icon">已开票
          </div>
          <p class="barrel">
            <span>开票数量：</span>{{ detailObj.totalInvoicedWeight||0 }}吨
          </p>
          <p class="barrel">
            <span>开票金额：</span>￥{{ detailObj.totalInvoicedAmount||0 }}
          </p>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="商品明细" name="first">
        <Table class="tableBox" :item-data="itemData" :table-height="446" :list-data="listData" />
      </el-tab-pane>
      <el-tab-pane label="保证金明细" name="second">
        <Table
          class="tableBox"
          :table-height="446"
          :item-data="cashDepositItemArr"
          :list-data="cashDepositArr"
        />
      </el-tab-pane>
      <el-tab-pane label="入库商品明细" name="three">
        <Table
          class="tableBox"
          :table-height="676"
          :is-border="true"
          :item-data="storageGoodsItemArr"
          :list-data="inventoryGoodsDetails"
        />
      </el-tab-pane>
      <el-tab-pane v-if="detailObj.isOverdue==='1'" label="逾期费明细" name="four">
        <Table
          class="tableBox"
          :is-border="true"
          :item-data="overdueItemArr"
          :list-data="overdueDataArr"
        />
      </el-tab-pane>
    </el-tabs>
    <PdfContract
      :sign-or-not="false"
      :visible.sync="visible"
      :url="detailObj.contractFileUrl"
    />
    <CushionCapitalRecord :id="detailObj.id" ref="cushionCapitalRecord" />
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import DetailsPage from '@/components/DetailsPage.vue'
import PdfContract from '@/components/PdfContract'
import CushionCapitalRecord from '@/components/CushionCapitalRecord.vue'
import { agentPurchaseContractDetail } from '@/api/generationMining'
import { roundUpToInteger } from '@/utils/util'
export default {
  components: { CushionCapitalRecord, DetailsPage, Table, PdfContract },
  data() {
    return {
      activeName: 'first',
      agentSettleObj: {},
      show: false,
      detailObj: {},
      visible: false,
      cashDepositArr: [],
      inventoryGoodsDetails: [],
      overdueDataArr: [],
      overdueItemArr: [
        { label: '逾期费用(元)', prop: 'totalOverdueCost' },
        { label: '已支付(元)', prop: 'paidOverdueCost' },
        { label: '待支付(元)', prop: 'overdueCost' }
      ],
      storageGoodsItemArr: [
        { width: 120, label: '品名', prop: 'goodsName' },
        { width: 120, label: '材质', prop: 'goodsMaterials' },
        { width: 120, label: '规格', prop: 'goodsSpecs' },
        { width: 120, label: '产地', prop: 'goodsProduct' },
        { width: 120, label: '件重(吨)', prop: 'goodsWeights' },
        { width: 120, label: '采购单价(元/吨)', prop: 'purchasePrice' },
        { width: 120, label: '入库数量(吨)', prop: 'invWeight' },
        { width: 120, label: '已提数量(吨)', prop: 'deliveryWeight' }
      ],
      ladderRateArr: [
        { label: '金额下限', prop: 'lowerLimit' },
        { label: '金额上限', prop: 'upperLimit' },
        { label: '基数(元/吨)', prop: 'base' },
        { label: '规则', prop: 'operFormula' }
      ],
      cashDepositItemArr: [
        { label: '待支付日期', prop: 'payableDate' },
        { label: '付款比例(%)', prop: 'paymentRate' },
        { label: '保证金金额(元)', prop: 'depositPayable' },
        {
          label: '支付状态',
          prop: 'payStatus',
          child: this.$store.getters.getDictionaryItem('deposit_payment_status')
        },
        { label: '支付时间', prop: 'realPayTime' }
      ],
      itemData: [
        { width: 120, label: '品名', prop: 'goodsName' },
        { width: 120, label: '材质', prop: 'goodsMaterials' },
        { width: 120, label: '规格', prop: 'goodsSpecs' },
        { width: 120, label: '产地', prop: 'goodsProduct' },
        { width: 120, label: '件重(吨)', prop: 'goodsWeights' },
        { width: 120, label: '采购数量(吨)', prop: 'purchaseWeight' },
        { width: 120, label: '采购单价(元/吨)', prop: 'purchasePrice' },
        { width: 120, label: '金额小计(元)', prop: 'totalPrice' }
        // { width: 120, label: '入库数量(吨)', prop: 'invWeight' },
        // { width: 120, label: '已提数量(吨)', prop: 'deliveryWeight' }
      ],
      listData: [],
      detailsItemArr1: [
        { label: '经销商', value: 'custCmpName' },
        { label: '代采商', value: 'fundsCmpName' },
        { label: '合作企业', value: 'supplierCmpName' },
        {
          label: '合同状态',
          value: 'contractStatus',
          child: this.$store.getters.getDictionaryItem('contractStatus')
        },
        { label: '代采费率', value: 'miningRate' },
        {
          label: '逾期费率',
          value: 'overdueInterestRate',
          rightWords: '% / 天'
        },
        { label: '保证金比例', value: 'depositRate', rightWords: '%' },
        { label: '保证金抵扣', value: 'depostDeductPayment', child: [{ id: '0', val: '不抵扣货款' }, { id: 1, val: '抵扣货款' }] },
        { label: '计划代采天数', value: 'purchaseDays', rightWords: '天' },
        { label: '交收仓库', value: 'deliveryWarehouseName' },
        {
          label: '交易类型',
          value: 'transType',
          child: [{ dictName: '锁量', dictId: 1 }, { dictName: '锁价', dictId: 2 }]
        },
        { label: '合同签订日', value: 'effectContractDate' }
      ]
    }
  },
  mounted() {
    // 详情跳转需要不同的页面
    if (
      this.$route.query.rowId ||
      this.$route.query.id ||
      this.$route.query.contractId
    ) {
      agentPurchaseContractDetail(
        this.$route.query.rowId ||
          this.$route.query.id ||
          this.$route.query.contractId,
        (res) => {
          this.detailObj = { ...res.data }
          if (this.detailObj.rateConfig) {
            this.getRate(this.detailObj.rateConfig)
          }
          this.overdueDataArr.push({
            overdueCost: res.data.overdueCost,
            paidOverdueCost: res.data.paidOverdueCost,
            totalOverdueCost: res.data.totalOverdueCost
          })
          this.detailObj.aggregateAmount = roundUpToInteger((Number(this.detailObj.totalPayment) || 0) + (Number(this.detailObj.estimateCost) || 0))
          if (this.detailObj.contractGoodsDetails) {
            this.listData = [...this.detailObj.contractGoodsDetails]
            this.listData.forEach((item) => {
              item.goodsSpecs = item.goodsSpecs === '-1' ? '混合规格' : item.goodsSpecs
              item.goodsProduct = item.goodsProduct === '-1' ? '不限' : item.goodsProduct
              item.goodsWeights = item.goodsWeights === -1 ? '不限' : item.goodsWeights
              item.balanceWeight =
                Number(item.invWeight) - Number(item.settledWeight)
            })
          }
          if (this.detailObj.depositDetails) {
            this.cashDepositArr = [...this.detailObj.depositDetails]
          }
          if (this.detailObj.inventoryGoodsDetails) {
            this.detailObj.inventoryGoodsDetails.forEach(item => {
              item.goodsSpecs = item.goodsSpecs === '-1' ? '混合规格' : item.goodsSpecs
              item.goodsProduct = item.goodsProduct === '-1' ? '不限' : item.goodsProduct
              item.goodsWeights = item.goodsWeights === -1 ? '不限' : item.goodsWeights
            })
            this.inventoryGoodsDetails = [...this.detailObj.inventoryGoodsDetails]
          }
        }
      )
    }
  },
  methods: {
    // 查看垫资记录
    checkDeliveryRecord() {
      this.$refs.cushionCapitalRecord.visible = true
    },
    // 查看合同
    viewContract() {
      this.visible = true
    },
    getRate(obj) {
      // 代采费率
      if (obj) {
        this.detailObj.miningRate = ''
        switch (obj.rateUnit) {
          case 1:
            this.detailObj.miningRate = obj.rateValue + '%'
            break
          case 2:
            this.detailObj.miningRate = obj.rateValue + '元/吨'
            break
          case 3:
            this.detailObj.miningRate = obj.rateValue + '元/吨'
            break
          default:
            break
        }
        switch (obj.rateCycle) {
          case 1:
            this.detailObj.miningRate += '/天'
            break
          case 2:
            this.detailObj.miningRate += '/周'
            break
          case 3:
            this.detailObj.miningRate += '/月'
            break
          case 4:
            this.detailObj.miningRate += '/年'
            break
          default:
            break
        }
      }
    }
  }
}
</script>
<style lang="scss">
.salesContractDetailsPageBox {
  padding: 24px;
  .overdue-box{
    text-align: center;
    line-height: 22px;
    height: 22px;
    width: 54px;
    border-radius: 6px;
    font-size: 14px;
    background-color: $assistColor5;
    color: #fff;
    margin-left: 6px;
  }
  .amountDetailBox{
    display: flex;
    align-content: space-between;
    padding: 50px 0 24px;
    .item{
      position: relative;
      margin-right: 16px;
      background-color: rgb(250,250,250);
      height: 180px;
      .textDescription{
        position: absolute;
        left: 0;
        top: 0;
        padding: 24px;
        width: calc(100% - 48px);
        height: calc(100% - 48px);
        p{
          text-align: left;
          color: #333;
          font-size: 14px;
          font-weight: 400;
          margin-top: 12px;
          span{
            color: #999;
          }
        }
        .iconBox{
          display: flex;
          align-items: center;
          color: #000;
          font-size: 16px;
          font-weight: 500;
          img{
            height: 32px;
            width: 32px;
            margin-right: 8px;
          }
        }
      }
      img{
        height: 100%;
        width: 100%;
      }
    }
    .item:last-of-type{
      margin-right: 0;
    }
  }
  .el-tabs__header {
    padding: 0;
    .el-tabs__active-bar {
      left: 24px;
    }
    .el-tabs__nav {
      padding-left: 24px;
    }
  }
  .el-tabs__content {
    .tableBox {
      width: calc(100% - 48px);
    }
  }
  .topBox {
    border: 1px solid #e7e7e7;
    border-top: 0;
    border-radius: 6px;
    .topTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 54px;
      border-radius: 6px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 1px solid rgba(244,244,244,1);
      background: rgba(249,249,249,1);
      padding: 0 24px;
      color: #333;
      > span {
        flex: 1;
      }
      > span:last-of-type {
        text-align: right;
      }
    }
  }
  .el-descriptions__body {
    padding: 0 4px !important;
  }
  .agentTitle {
    font-size: 16px;
    font-weight: 700;
    color: $mainFontColor;
  }
  .settlement-price-box {
    display: flex;
    align-items: center;
    margin: 32px 64px;
    > span {
      padding-right: 4px;
      font-size: 14px;
      width: 200px;
      color: $mainFontColor;
    }
    .el-input {
      width: 220px;
      margin-right: 32px;
    }
  }
  .cancelButton {
    text-align: center;
  }

  .tableBox {
    margin: 0 auto 18px;
    text-align: center;
    width: calc(100% - 128px);
  }
}
</style>
